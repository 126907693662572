;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"6ec83eb767d15ac56234ad45fa6fdd44e2271f25"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import { createBeforeSend } from './src/utils/sentry';
import baseOptions from './sentry.base.config';

Sentry.init({
  ...baseOptions,
  beforeSend: createBeforeSend,
  enableTracing: false, // 성능 하락 방지를 위해 비활성화
});
